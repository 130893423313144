jQuery(document).ready(function($){

	var VideoModal = jQuery('#VideoModal');
	var ContactForm = jQuery('#ClientWebFormForm');
	var ClientName = jQuery('#ClientFirstName');
	var ClientPhone = jQuery('#ClientPhone');
	var ClientEmail = jQuery('#ClientEmail');
	var ClientVendors = jQuery('#ClientNumeroDeVendedores');
	var ErrorSpan = jQuery('<div class="alert alert-warning"></div>');

	VideoModal.on('show.bs.modal', function(){
		VideoModal.find('.modal-dialog').html('<iframe width="100%" height="400" src="//www.youtube.com/embed/pvK-gNv0hTo" frameborder="0" allowfullscreen></iframe>');
	});

	VideoModal.on('hide.bs.modal', function(){
		VideoModal.find('.modal-dialog').html(null);
	});

	ContactForm.submit(function(e){
		if (!valid_form()){
			e.preventDefault();
		}
	});

	function valid_form(){
		jQuery(ErrorSpan).empty();
		var name = jQuery.trim(ClientName.val());
		var email = jQuery.trim(ClientEmail.val());
		var phone = jQuery.trim(ClientPhone.val());
		var vendors = jQuery.trim(ClientVendors.val());
		if (name.length === 0 || email.length === 0 || isNaN(phone) || isNaN(vendors)){
			ErrorSpan.append('Por favor llene el formulario correctamente');
			ContactForm.after(ErrorSpan);
			return false;
		}
		return true;
	}

	if (typeof INVITE_CODE !== 'undefined')
	{
		if (INVITE_CODE)
		{
			jQuery('#promotionCodeModal').modal('show');
		}
	}

	$('.navbar-container > .container p.lead').each(function(element){
		if ($(this).height() < 30)
		{
			var h1 = $('.navbar-container > .container h1');
			h1.css('margin-top', parseInt(h1.css('margin-top')) + 15);
		}
	});
});
