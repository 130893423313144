
jQuery(document).ready(function($) {

	var backgrounds = $('.background');
	var elements = $('.element');
	var carouserl_buttons = $('.carousel-button');
	var navigation_button = $('.navigation-button');
	var arrow_left = $('#arrowLeft');
	var arrow_right = $('#arrowRight');
	var old_center = -1;
	var is_animating = false;
	var window_width = window.innerWidth;
	var carousel_cookie;
	var jWindow = $(window);

	window.addEventListener('resize', function(event){
		window_width = window.innerWidth;
	});

	var carousel_start = function carousel_start(first_center)
	{
		$(backgrounds[first_center]).attr('style', 'z-index: 3; opacity: 1').addClass('current-background');
		backgrounds.removeClass('background-hidden');
		$(elements[first_center]).attr('style', 'transform:none; display:none').fadeIn('slow', function(){
			$(this).attr('class', 'element animated current-element');
		});

		set_active_button(first_center);

		old_center = first_center;
		center = first_center;
		arrow_left.attr('data-next-center', parseInt(center) - 1 < 0 ? 3 : parseInt(center) - 1);
		arrow_right.attr('data-next-center', parseInt(center) + 1 > 3 ? 0 : parseInt(center) + 1);
		for (var i = 0; i < 4; i++)
		{
			if (i != first_center)
			{
				var pull_class = i > first_center ? 'pull-to-right' : 'pull-to-left';
				$(elements[i]).attr('class', 'element ' + pull_class).removeAttr('style');
			}
		}
		carouserl_buttons.click(function(){
			if (is_animating === false)
				carousel_twist($(this).index());
		});

		navigation_button.click(function(){
			if (is_animating === false)
			{
				carousel_twist($(this).attr('data-next-center'));
			}
		});
	}

	var carousel_twist = function carousel_twist(new_center)
	{
		if (new_center === old_center)
			return true;

		turn_elements(new_center);
		turn_background(new_center);
		set_active_button(new_center);
		old_center = new_center;
	}

	var turn_elements = function turn_elements(new_center)
	{
		is_animating = true;

		var new_width = window_width;
		if (new_center > old_center)
			new_width = new_width * (-1);

		$(elements[old_center]).attr('style', 'transform: translateX(' + new_width + 'px);');
		$(elements[new_center]).attr('class', 'element animated current-element').attr('style', 'transform: translateX(0px);');

		setTimeout(function(){
			center = new_center;
			arrow_left.attr('data-next-center', parseInt(center) - 1 < 0 ? 3 : parseInt(center) - 1);
			arrow_right.attr('data-next-center', parseInt(center) + 1 > 3 ? 0 : parseInt(center) + 1);
			for (var i = 0; i < 4; i++)
			{
				if (i != new_center && i != old_center)
				{
					var pull_class = i > new_center ? 'pull-to-rigth' : 'pull-to-left';
					$(elements[i]).attr('class', 'element ' + pull_class).removeAttr('style');
				}
			}
			is_animating = false;
		}, 1000);
	}

	var turn_background = function turn_background(new_center)
	{
		var new_background = $(backgrounds[new_center]);
		var old_background = $(backgrounds[old_center]);
		new_background.addClass('background-animated current-background').attr('style', 'z-index: 4;opacity:1');
		setTimeout(function(){
			new_background.attr('style', 'z-index: 3; opacity: 1');
			old_background.attr('style', 'opacity:0; z-index: 2;');
		}, 1000);
	}

	var set_active_button = function set_active_button(new_center)
	{
		carouserl_buttons.not(carouserl_buttons[new_center]).attr('style', 'border-width: 1px');
		$(carouserl_buttons[new_center]).attr('style', 'border-width: 5px');
	}

	setInterval(function(){
		if (!is_animating && parseInt(jWindow.scrollTop()) <= 450)
		{
			var carousel = JSON.parse(localStorage.getItem('carousel'));
			carousel[center] = parseInt(carousel[center]) + 2;
			localStorage.setItem('carousel', JSON.stringify(carousel));
		}
	}, 2000);

	var carousel = JSON.parse(localStorage.getItem('carousel'));
	var min_index = 0;

	if (false)
	{
		for (var i = 1; i <= 3; i++)
		{
			if (parseInt(carousel[i]) < parseInt(carousel[min_index]))
			{
				min_index = i;
			}
		}
	}
	else
	{
		localStorage.setItem('carousel', '{"0": 0, "1": 0, "2": 0, "3": 0}');
	}

	carousel_start(min_index);
});
