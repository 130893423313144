jQuery(document).ready(function($){
	var Thumbnails = $('.thumbnails > div > div');

	jQuery.each(Thumbnails, function(index){
		jQuery(this).click(function(){
			Thumbnails.removeClass('active');
			jQuery(this).addClass('active');
		});
	});

});
