jQuery(document).ready(function($) {
	var elements_to_hidden = $('.hidden-in-scroll');
	var jWindow = $(window);
	var navbar_container = $('.navbar-container');
	var navbar_transparent = $('.navbar-transparent');
	var navbar_collapse = $('.navbar-collapse');
	var body = $('body');
	var window_top = 0;
	var height_navbar_container = navbar_container.height() - 70;

	if (navbar_transparent.hasClass('static'))
		return;

	jWindow.scroll(function(){
		update_scroll();
	});

	var update_scroll = function update_scroll()
	{
		window_top = jWindow.scrollTop();

		if (window_top <= height_navbar_container)
		{
			elements_to_hidden.fadeIn('fast');
			navbar_container.removeClass('animating').removeClass('fixed').css('top', '0px');
			navbar_transparent.css('top', '0px');
			body.css('padding-top', '0px');
		}
		else
		{
			elements_to_hidden.fadeOut('fast');
			navbar_container.removeClass('animating').addClass('fixed').css('top', -height_navbar_container + 'px');
			navbar_transparent.css('top', (height_navbar_container) + 'px');
			body.css('padding-top', height_navbar_container + 70);
		}

		if (navbar_collapse.hasClass('in'))
		{
			navbar_collapse.collapse('hide');
		}
	}

	navbar_container.css('height', navbar_container.height() + 30)

	navbar_collapse.on('show.bs.collapse', function(){
		if (window_top > 0)
		{
			navbar_container.addClass('animating').css('top', '0px');
			navbar_transparent.css('top', '0px');
		}
		else
		{
			elements_to_hidden.fadeOut('fast');
		}
		if (height_navbar_container === 0)
		{
			navbar_container.css('height', '260px');
		}
	});

	navbar_collapse.on('hide.bs.collapse', function(){
		if (window_top > 0)
		{
			navbar_container.addClass('animating').css('top', -height_navbar_container + 'px');
			navbar_transparent.css('top', height_navbar_container + 'px');
		}
		else
		{
			elements_to_hidden.fadeIn('fast');
		}
		if (height_navbar_container === 0)
		{
			navbar_container.css('height', '70px');
		}
	});

	update_scroll();
});
